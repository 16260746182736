<template>
  <div>
    <v-tabs grow v-model="currentTab">
      <v-tab>Internal Sensors</v-tab>
      <v-tab>External Sensors</v-tab>
    </v-tabs>
    <loading v-if="$apollo.loading" />
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              @click="changeOrder('NAME')"
              class="text-uppercase"
            >
              Name
              <sorting-arrows />
            </th>
            <th
              class="text-center text-uppercase"
            >
              Value
            </th>
            <th
              class="text-center text-uppercase"
            >
              Last Update
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('UPDATE_RATE')"
            >
              Update Rate
              <sorting-arrows />
            </th>
            <th
              class="text-center text-uppercase" >
              History
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="allSensors && allSensors.length == 0">
            <td class="text-center">
              <h3>
                <v-icon size="20">
                    {{ icons.mdiCancel }}
                </v-icon>
                No items
              </h3>
            </td>
          </tr>
          <tr
            v-else
            v-for="item in allSensors"
            :key="item.id"
          >
            <td>
              <div v-if="item.name">
                {{ getName(item.name) }}
              </div>
               <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              <v-icon v-if="item.name == 'tilt' && item.last_value > 30" color="warning" size="17">{{ icons.mdiAlert }}</v-icon>
              {{ getLastValue(item) }}
            </td>
            <td class="text-center">
              <div v-if="item.sensor_data && item.sensor_data.length > 0">
                {{ moment.unix(item.sensor_data[0].last_update).format("YYYY-MM-DD HH:mm") }}
              </div>
              <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              <div v-if="item.update_rate">
                <v-menu
                  v-if="role === 'superadmin' && item.name !== 'battery_charge' && item.name !== 'solar_input_w_average'"
                  open-on-hover
                  transition="scale-transition"
                  allow-overflow
                  offset-x
                  nudge-width="50px"
                  close-delay="100"
                  rounded
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      @click="openUpdateModal(item)"
                    >
                      {{ item.update_rate }} min
                    </span>
                  </template>
                  <v-icon
                    style="overflow: hidden;"
                    @click="openUpdateModal(item)"
                    size="20"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-menu>
                <span v-else style="opacity: 0.65">{{ item.update_rate }} min</span>
              </div>
              <div v-else>
                <span style="opacity: 0.65"> {{ item.name == 'solar_input_w_average' ? '1 day' : '/' }} </span>
              </div>
            </td>
            <td class="text-center">
              <v-btn
                icon
                small
              >
                <v-icon size="20">
                  {{ icons.mdiFileClockOutline   }}
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      v-if="allSensors"
      :count="countPages"
      :page.sync="currentPage"
    />

    <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="350px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ selectedSensor ? selectedSensor.name : '' }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="newUpdateRate"
                label="Update rate"
                type="number"
                min="0"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="() => {
            isDialogVisible = false
            newUpdateRate = null
          }"
        >
          Close
        </v-btn>
        <v-btn
          :disabled="!isValid"
          @click="save"
          color="success"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import { ref } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import {
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiViewListOutline,
  mdiCancel,
  mdiFileClockOutline,
  mdiAlert,
} from '@mdi/js'
import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
// import _isNil from 'lodash/isNil'
import Loading from '../Loading.vue'
import SortingArrows from '../SortingArrows.vue'

export default {
  name: 'SensorsTable',
  components: {
    Pagination,
    Loading,
    SortingArrows,
  },
  props: {
    search: {
      required: false,
      default: null,
      type: String,
    },
  },
  apollo: {
    sensors: {
      query: gql`
        query (
          $device_id: ID!
          $type: String
          $page: Int!
          $search: String
          $orderBy: [QuerySensorsOrderByOrderByClause!]
        ) {
          sensors(device_id: $device_id, type: $type, page: $page, search: $search, orderBy: $orderBy) {
            data {
              id
              name
              last_value
              type
              update_rate
              measuring_unit
              sensor_data {
                last_update
              }
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      debounce: 350,
      variables() {
        return {
          search: this.search ?? undefined,
          type: this.currentTab === 0 ? 'internal' : 'external',
          device_id: this.$route.params.id,
          orderBy: [
            {
              column: this.column ?? 'NAME',
              order: this.order ?? 'ASC',
            },
          ],
          page: this.currentPage,
        }
      },
      skip() {
        return !this.$route.params.id
      },
      result(result) {
        this.allSensors = result.data.sensors.data.filter(sensor => {
          if (
            sensor.name !== 'battery_a' &&
            sensor.name !== 'battery_v' &&
            sensor.name !== 'solar_input_v' &&
            sensor.name !== 'solar_input_a'
          )
            return sensor
        })
        this.countPages = result.data.sensors.paginatorInfo.lastPage
        this.currentPage = result.data.sensors.paginatorInfo.currentPage
      },
      error(error) {
        console.log(JSON.stringify(error.message))
      },
    },
  },
  setup(props) {
    const role = localStorage.getItem('userAbility')
    const currentPage = ref(1)
    const countPages = ref(1)
    const searchProp = ref(props.search)
    const showModal = ref(false)
    const showDeleteModal = ref(false)
    const column = ref('NAME')
    const order = ref('ASC')
    const allSensors = ref([])
    const selectedSensor = ref(null)
    const currentTab = ref(0)
    const isDialogVisible = ref(false)
    const newUpdateRate = ref(null)

    const getBatteryColor = number => {
      if (number >= 30) return 'success'
      if (number < 30 && number >= 10) return 'warning'

      return 'error'
    }

    return {
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiViewListOutline,
        mdiCancel,
        mdiFileClockOutline,
        mdiAlert,
      },
      role,
      allSensors,
      currentPage,
      countPages,
      searchProp,
      showModal,
      showDeleteModal,
      column,
      order,
      getBatteryColor,
      currentTab,
      isDialogVisible,
      selectedSensor,
      newUpdateRate,
    }
  },
  methods: {
    openUpdateModal(item) {
      this.isDialogVisible = true
      this.selectedSensor = item
      this.newUpdateRate = item.update_rate
    },
    changeOrder(column) {
      this.column = column
      this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
    },
    async save() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: ID!, $update_rate: Int!) {
              updateSensor(id: $id, update_rate: $update_rate) {
                id
              }
            }
          `,
          variables: {
            update_rate: parseInt(this.newUpdateRate, 10),
            id: this.selectedSensor.id,
          },
        })
        this.$toastr.s('Update rate successfully updated!')
        this.$apollo.queries.sensors.refetch()
      } catch (err) {
        console.log(err)
        // TODO: errors
        this.$toastr.e('Failed!')
      } finally {
        this.isDialogVisible = false
        this.selectedSensor = null
      }
    },
    getName(name) {
      //   if (name === 'solar_input_a') return 'Solar input (a)'
      //   if (name === 'solar_input_v') return 'Solar input (v)'
      //   if (name === 'battery_v') return 'Battery (v)'
      //   if (name === 'battery_a') return 'Battery (a)'
      if (name === 'solar_input_w') return 'Solar Input'
      if (name === 'solar_input_w_average') return "Solar Input Yesterday's Average"
      if (name === 'battery_charge') return 'Battery Charge'
      if (name === 'battery_capacity') return 'Battery Capacity'
      if (name === 'battery_temperature') return 'Battery Temperature'
      if (name === 'casing_temperature') return 'Casing Temperature'
      if (name === 'casing_humidity') return 'Casing Humidity'
      if (name === 'air_quality') return 'Air Quality Index (PM1, PM2.5, PM10)'

      return name[0].toUpperCase() + name.slice(1)
    },
    getLastValue(sensor) {
      if (!sensor.last_value) return '/'

      if (sensor.name === 'air_quality') {
        const value = JSON.parse(sensor.last_value)

        return `${value[0]}  µg/m³, ${value[1]} µg/m³, ${value[2]} µg/m³`
      }

      if (sensor.name === 'gps') {
        const value = JSON.parse(sensor.last_value)

        return `lat: ${value.lat}, long: ${value.long}`
      }

      return `${sensor.last_value}${sensor.measuring_unit}`
    },
  },
  computed: {
    isValid() {
      if (this.newUpdateRate === null || this.newUpdateRate === undefined || this.newUpdateRate <= 0) return false

      return true
    },
  },
}
</script>
