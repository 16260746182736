<template>
  <div class="my-3">
    <v-row class="px-3">
      <v-spacer />
      <h2> SENSORS ({{ device ? device.id_imei : '' }})</h2>
      <v-spacer />
    </v-row>

    <v-row class="px-3">
      <v-btn
        color="primary"
        dark
        @click="$router.push('/devices')"
      >
        <v-icon size="20">
          {{ icons.mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div>
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          dense
          outlined
        ></v-text-field>
      </div>
    </v-row>
    <sensors-table
      ref="sensorsTable"
      :search="search"
      @change="refetchData"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiArrowLeft } from '@mdi/js'
import SensorsTable from '@/views/components/sensors/SensorsTable'
import gql from 'graphql-tag'

export default {
  components: {
    SensorsTable,
  },

  apollo: {
    device: {
      query: gql`
        query ($id: ID!) {
          device(id: $id) {
            id
            id_imei
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      skip() {
        return !this.$route.params.id
      },
      error(error) {
        console.log(JSON.stringify(error.message))
      },
    },
  },
  setup() {
    const role = localStorage.getItem('userAbility')
    const search = ref('')
    const showModal = ref(false)

    return {
      icons: {
        mdiMagnify,
        mdiArrowLeft,
      },
      role,
      search,
      showModal,
    }
  },

  methods: {
    refetchData() {
      this.$refs.adminsTable.getSensors()
    },
  },
}
</script>
